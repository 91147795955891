<template>
	<div class="form">
		<b-row class="mb-3">
			<b-col cols="6">
				<b-input-group>
					<b-form-input
						v-model="filter"
						type="text"
						:placeholder="this.FormMSG(222, 'Type to Search')"
						@change="handleChangeFilter"
						@input="handleChangeFilter(filter)"
					/>
					<b-input-group-append class="cursor-pointer">
						<b-input-group-text class="btn-search">
							<button v-if="!searched" class="btn-transparent" @click="handleChangeFilter(filter)">
								<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" />
							</button>
							<button v-else class="btn-transparent" @click="handleChangeFilter('')">
								<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" />
							</button>
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<b-col cols="6" class="pt-1">
				<b-form-checkbox v-model="showCo2" :value="true" :unchecked-value="false" size="lg">{{ FormMSG(357, 'Show CO2 info') }}</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row>
			<b-col :cols="manageColsField(5, 4, 4, 3)" class="pr-0">
				<div class="header-budget-col fw-500 fs-14">{{ FormMSG(154, 'Cost Codes') }}</div>
			</b-col>
			<b-col :cols="manageColsField(3, 3, 4, 4)" class="pl-0 pr-0">
				<div class="header-budget-col fw-500 fs-14 ml-1">{{ FormMSG(159, 'Spent to date') }}</div>
			</b-col>
			<b-col cols="2" class="pl-0 pr-0"></b-col>
			<b-col cols="2" class="pl-0">
				<div class="header-budget-col fw-500 fs-14">{{ FormMSG(165, 'Cost Reporting') }}</div>
			</b-col>
		</b-row>
		<b-row class="header-table-budgets">
			<b-col :cols="manageColsField(5, 4, 4, 3)" class="d-flex cursor-pointer" @click="handleClickHeader('cost_center')">
				<div class="w-75">{{ FormMSG(113, 'Name') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.cost_center === 0"
							:size="16"
							:color="getColorChevrons('cost_center')"
							:fill="getColorChevrons('cost_center')"
						/>
						<chevron-down
							v-if="sortFilter.cost_center === 1"
							:size="16"
							:color="getColorChevrons('cost_center')"
							:fill="getColorChevrons('cost_center')"
						/>
						<chevron-up
							v-if="sortFilter.cost_center === 2"
							:size="16"
							:color="getColorChevrons('cost_center')"
							:fill="getColorChevrons('cost_center')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('purchaseOrdersTotal')">
				<div class="w-75">{{ FormMSG(256, 'Open PO') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.purchaseOrdersTotal === 0"
							:size="16"
							:color="getColorChevrons('purchaseOrdersTotal')"
							:fill="getColorChevrons('purchaseOrdersTotal')"
						/>
						<chevron-down
							v-if="sortFilter.purchaseOrdersTotal === 1"
							:size="16"
							:color="getColorChevrons('purchaseOrdersTotal')"
							:fill="getColorChevrons('purchaseOrdersTotal')"
						/>
						<chevron-up
							v-if="sortFilter.purchaseOrdersTotal === 2"
							:size="16"
							:color="getColorChevrons('purchaseOrdersTotal')"
							:fill="getColorChevrons('purchaseOrdersTotal')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" style="padding-left: 4px" @click="handleClickHeader('budgetDetailStartOfPeriodValue')">
				<div class="w-75">{{ FormMSG(268, 'Initial spent') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.budgetDetailStartOfPeriodValue === 0"
							:size="16"
							:color="getColorChevrons('budgetDetailStartOfPeriodValue')"
							:fill="getColorChevrons('budgetDetailStartOfPeriodValue')"
						/>
						<chevron-down
							v-if="sortFilter.budgetDetailStartOfPeriodValue === 1"
							:size="16"
							:color="getColorChevrons('budgetDetailStartOfPeriodValue')"
							:fill="getColorChevrons('budgetDetailStartOfPeriodValue')"
						/>
						<chevron-up
							v-if="sortFilter.budgetDetailStartOfPeriodValue === 2"
							:size="16"
							:color="getColorChevrons('budgetDetailStartOfPeriodValue')"
							:fill="getColorChevrons('budgetDetailStartOfPeriodValue')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('totalSpent')">
				<div class="w-85">{{ FormMSG(264, 'Total spent') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.totalSpent === 0"
							:size="16"
							:color="getColorChevrons('totalSpent')"
							:fill="getColorChevrons('totalSpent')"
						/>
						<chevron-down
							v-if="sortFilter.totalSpent === 1"
							:size="16"
							:color="getColorChevrons('totalSpent')"
							:fill="getColorChevrons('totalSpent')"
						/>
						<chevron-up
							v-if="sortFilter.totalSpent === 2"
							:size="16"
							:color="getColorChevrons('totalSpent')"
							:fill="getColorChevrons('totalSpent')"
						/>
					</span>
				</div>
			</b-col>
			<b-col v-if="showCo2" cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('kgCoTwo')">
				<div class="w-75">{{ FormMSG(258, 'CO2') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down v-if="sortFilter.kgCoTwo === 0" :size="16" :color="getColorChevrons('kgCoTwo')" :fill="getColorChevrons('kgCoTwo')" />
						<chevron-down v-if="sortFilter.kgCoTwo === 1" :size="16" :color="getColorChevrons('kgCoTwo')" :fill="getColorChevrons('kgCoTwo')" />
						<chevron-up v-if="sortFilter.kgCoTwo === 2" :size="16" :color="getColorChevrons('kgCoTwo')" :fill="getColorChevrons('kgCoTwo')" />
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('amountLeft')">
				<div class="w-75">{{ FormMSG(259, 'ETC') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.amountLeft === 0"
							:size="16"
							:color="getColorChevrons('amountLeft')"
							:fill="getColorChevrons('amountLeft')"
						/>
						<chevron-down
							v-if="sortFilter.amountLeft === 1"
							:size="16"
							:color="getColorChevrons('amountLeft')"
							:fill="getColorChevrons('amountLeft')"
						/>
						<chevron-up
							v-if="sortFilter.amountLeft === 2"
							:size="16"
							:color="getColorChevrons('amountLeft')"
							:fill="getColorChevrons('amountLeft')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer">
				<div class="w-75">
					{{ FormMSG(260, 'Budget') }}
					<span class="d-inline-block ml-2" v-if="canLockBudget && !hideLock" @click="handleClickLockedBudget">
						<i v-if="budgetIsLocked" class="fa fa-lock fs-16" aria-hidden="true" />
						<i v-else class="fa fa-unlock fs-16" aria-hidden="true"></i>
					</span>
				</div>
				<div class="w-25" @click="handleClickHeader('budgetDetailInitialBudget')">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.budgetDetailInitialBudget === 0"
							:size="16"
							:color="getColorChevrons('budgetDetailInitialBudget')"
							:fill="getColorChevrons('budgetDetailInitialBudget')"
						/>
						<chevron-down
							v-if="sortFilter.budgetDetailInitialBudget === 1"
							:size="16"
							:color="getColorChevrons('budgetDetailInitialBudget')"
							:fill="getColorChevrons('budgetDetailInitialBudget')"
						/>
						<chevron-up
							v-if="sortFilter.budgetDetailInitialBudget === 2"
							:size="16"
							:color="getColorChevrons('budgetDetailInitialBudget')"
							:fill="getColorChevrons('budgetDetailInitialBudget')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('budgetDetailInitial')">
				<div class="w-75">
					{{ FormMSG(261, 'EFC') }}
				</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.budgetDetailInitial === 0"
							:size="16"
							:color="getColorChevrons('budgetDetailInitial')"
							:fill="getColorChevrons('budgetDetailInitial')"
						/>
						<chevron-down
							v-if="sortFilter.budgetDetailInitial === 1"
							:size="16"
							:color="getColorChevrons('budgetDetailInitial')"
							:fill="getColorChevrons('budgetDetailInitial')"
						/>
						<chevron-up
							v-if="sortFilter.budgetDetailInitial === 2"
							:size="16"
							:color="getColorChevrons('budgetDetailInitial')"
							:fill="getColorChevrons('budgetDetailInitial')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('budgetDetailVariance')">
				<div class="w-75">{{ FormMSG(262, 'Variance') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.budgetDetailVariance === 0"
							:size="16"
							:color="getColorChevrons('budgetDetailVariance')"
							:fill="getColorChevrons('budgetDetailVariance')"
						/>
						<chevron-down
							v-if="sortFilter.budgetDetailVariance === 1"
							:size="16"
							:color="getColorChevrons('budgetDetailVariance')"
							:fill="getColorChevrons('budgetDetailVariance')"
						/>
						<chevron-up
							v-if="sortFilter.budgetDetailVariance === 2"
							:size="16"
							:color="getColorChevrons('budgetDetailVariance')"
							:fill="getColorChevrons('budgetDetailVariance')"
						/>
					</span>
				</div>
			</b-col>
			<b-col v-show="!hideActionColumn" cols="1" class="text-center cursor-pointer">
				<div>{{ FormMSG(263, 'Action') }}</div>
			</b-col>
		</b-row>
		<div class="content-table-budgets" :style="{ height: budgets.length > 0 ? '700px' : '40px' }">
			<div v-if="budgets.length > 0">
				<perfect-scrollbar ref="scrollTreeNode" :style="{ height: budgets.length > 0 ? '700px' : '40px' }">
					<tree-node-budget
						v-for="(budget, index) in budgets"
						:id="`t_${budget.budgetDetailId}`"
						:key="index"
						:budget="budget"
						:department-id="departmentId"
						:start-date="startDate"
						:end-date="endDate"
						:show-column-co2="showCo2"
						:hide-action="hideActionColumn"
						:parent-node-str="`${budget.budgetDetailId}`"
						@tree-node-budget:modify-EFC="handleTreeNodeBudgetModifyEFC"
						@tree-node-budget:edit="handleTreeNodeBudgetEdit"
						@tree-node-budget:add-sub-category="handleTreeNodeBudgetAddSubCategory"
						@hook:mounted="scrollToByRef"
					/>
				</perfect-scrollbar>
			</div>
			<div v-else>
				<b-row style="border: 1px solid #dfdfdf !important; margin: 0">
					<b-col cols="12" class="text-center">
						{{ FormMSG(112, 'No category found') }}
					</b-col>
				</b-row>
			</div>
		</div>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(953, 'Info!')"
			class="modal-success"
			centered
			no-close-on-esc
			no-close-on-backdrop
			hide-header-close
			v-model="showModalInfoLocked"
			@ok="handleOk"
			ok-variant="success"
			ok-only
		>
			{{ msgInfoLocked }}
		</b-modal>
		<modal-for-sign-document
			:document="{}"
			:open="showModalForSignDocument"
			:title="FormMSG(587, 'Password')"
			just-verify-password
			@modal-for-sign-document:password-ok="handleModalForSignDocumentPasswordOk"
			@modal-for-sign-document:close="handleModalForSignDocumentClose"
		/>
	</div>
</template>

<script>
import TreeNodeBudget from '@/components/Budget/TreeNodeBudget';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { ChevronsUpDown, Search, X, ChevronDown, ChevronUp, Lock, Unlock } from 'lucide-vue';
import { searchInTheTree } from '@/shared/utils';
import { store } from '@/store';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import { updMyProject } from '@/cruds/project.crud';
import ModalForSignDocument from '@/components/Modals/ModalForSignDocument';
import _ from 'lodash';

export default {
	name: 'TableHierarchicalBudget',
	mixins: [languageMessages, GlobalMixin],
	props: {
		departmentId: { type: [Number, String], default: null },
		budgetParents: { type: Array, default: [] },
		startDate: { type: String, required: false },
		endDate: { type: String, required: false },
		filters: { type: String, default: '', required: false },
		hideAction: { type: Boolean, default: false },
		hideLock: { type: Boolean, default: false }
	},
	components: {
		TreeNodeBudget,
		ChevronsUpDown,
		Search,
		X,
		ChevronDown,
		ChevronUp,
		Lock,
		Unlock,
		PerfectScrollbar,
		ModalForSignDocument
	},
	data() {
		return {
			selectedCategory: 0,
			selectedDepartment: 0,
			filter: '',
			budgets: [],
			budgetsOriginal: [],
			sortFilter: {
				cost_center: 0, // 0: none, 1: desc, 2: asc
				budgetDetailInitial: 0,
				expenseTotal: 0,
				salaries: 0,
				purchaseOrdersTotal: 0,
				kgCoTwo: 0,
				amountLeft: 0,
				budgetDetailStartOfPeriodValue: 0,
				totalSpent: 0,
				budgetDetailInitialBudget: 0,
				budgetDetailVariance: 0
			},
			loadSearch: false,
			searched: false,
			showCo2: true,

			hideActionColumn: false,
			budgetIsLocked: false,

			showModalInfoLocked: false,
			msgInfoLocked: '',

			showModalForSignDocument: false
		};
	},
	watch: {
		budgetParents: {
			handler(val) {
				if (!_.isNil(val)) {
					this.budgetIsLocked = store.state.myProject.budgetLocked;
					this.budgets = [];
					this.budgets = [...val];
					this.budgetsOriginal = [...val];
				}
			},
			immediate: true
		},
		hideAction: {
			handler(val) {
				this.hideActionColumn = val;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		canAddOrEditCode() {
			return store.canEditAndAddCode();
		},
		canModifyEFC() {
			return store.canModifyEstimatedFinalCost();
		},
		canLockBudget() {
			return store.canLockBudget();
		}
	},
	methods: {
		scrollToByRef() {
			if (this.$route.query.search && this.$route.query.search !== '') {
				this.$refs['scrollTreeNode'].$el.scrollTop = document.getElementById(`t_${this.$route.query.search}`).scrollHeight;
			}
		},
		handleModalForSignDocumentClose() {
			this.showModalForSignDocument = false;
		},
		async handleModalForSignDocumentPasswordOk() {
			await updMyProject(parseInt(store.state.myProject.id), {
				budgetLocked: true,
				projectType: store.state.myProject.projectType
			});
			this.msgInfoLocked = this.FormMSG(959, 'You have to reload the app to setup the new parameter');
			this.showModalInfoLocked = true;
		},
		handleOk() {
			if (!this.budgetIsLocked) {
				this.$router.go();
				this.showModalInfoLocked = true;
			} else {
				this.showModalInfoLocked = true;
			}
		},
		async handleClickLockedBudget() {
			if (this.budgetIsLocked) {
				this.msgInfoLocked = this.FormMSG(
					952,
					'When budget is locked, we have no way to modify anymore the initial budget amount (from Config/Project and here)'
				);
				this.showModalInfoLocked = true;
			} else {
				this.showModalForSignDocument = true;
			}
		},
		handleTreeNodeBudgetModifyEFC(payload) {
			this.$emit('table-hierarchical-budget:modify-EFC', payload);
		},
		handleTreeNodeBudgetEdit(payload) {
			this.$emit('table-hierarchical-budget:edit', payload);
		},
		handleTreeNodeBudgetAddSubCategory(payload) {
			this.$emit('table-hierarchical-budget:add-sub-category', payload);
		},
		manageColsField(valCond1, valCond2, valCond3, defaultVal) {
			if (!this.showCo2 && this.hideActionColumn) {
				return valCond1;
			}
			if (!this.showCo2 && !this.hideActionColumn) {
				return valCond2;
			}
			if (this.showCo2 && this.hideActionColumn) {
				return valCond3;
			}
			return defaultVal;
		},
		resetSearch(element) {
			this.searched = false;
			for (let i = 0; i < element.length; i++) {
				let data = element[i];
				data.is_selected = false;
				if (data.children) {
					this.resetSearch(data.children);
				}
			}
		},
		handleChangeFilter(payload) {
			this.loadSearch = true;
			this.filter = payload;
			let filter = payload.trim().toLowerCase();

			if (filter === '') {
				this.searched = false;
				this.budgets = this.budgetsOriginal;
				let array = [];
				for (let i = 0; i < this.budgetsOriginal.length; i++) {
					let element = this.budgetsOriginal[i];
					if (element.children) {
						this.resetSearch(element.children);
					}
					element.is_selected = false;
					array.push(element);
				}
				this.budgetsOriginal = array;
			} else {
				this.searched = true;
				let arr = [];
				for (let i = 0; i < this.budgetsOriginal.length; i++) {
					let element = this.budgetsOriginal[i];
					const resultSearch = searchInTheTree(element, 'name', filter);
					if (resultSearch !== '') {
						element.is_selected = true;
						this.prepareResultTree(element.children, resultSearch);
						arr.push(element);
					} else {
						if (element.name.toLowerCase().indexOf(filter) > -1) {
							element.is_selected = true;
							arr.push(element);
						}
					}
				}

				this.budgets = arr;
			}

			this.loadSearch = false;
		},
		prepareResultTree(data, stringIndex) {
			if (stringIndex === '') return;
			let stringIndexSplited = stringIndex.split('.');
			let currIndex = parseInt(stringIndexSplited[0]);
			data[currIndex].is_selected = true;
			stringIndexSplited.shift();
			if (data[currIndex].children) {
				this.prepareResultTree(data[currIndex].children, stringIndexSplited.join('.'));
			}
		},
		handleClickHeader(field) {
			this.addSortFilter(field);
			this.applySortFilter(field);
		},
		addSortFilter(field) {
			let initValueSortFilter = {
				cost_center: 0,
				budgetDetailInitial: 0,
				expenseTotal: 0,
				salaries: 0,
				purchaseOrdersTotal: 0,
				kgCoTwo: 0,
				amountLeft: 0
			};
			initValueSortFilter[field] = this.sortFilter[field];
			this.sortFilter = initValueSortFilter;

			if (this.sortFilter[field] === 2) {
				this.sortFilter[field] = 0;
			} else {
				this.sortFilter[field]++;
			}
		},
		applySortFilter(field) {
			this.budgets = [];

			const sortByMapped = (map, compareFn) => (a, b) => compareFn(map(a), map(b));
			let byValue = null;
			const cond = (e) => e[field];

			if (this.sortFilter[field] === 1) {
				byValue = (a, b) => a - b;
				const resultByValue = sortByMapped(cond, byValue);

				this.budgets = this.budgetsOriginal.sort(resultByValue);
			} else if (this.sortFilter[field] === 2) {
				byValue = (a, b) => b - a;
				const resultByValue = sortByMapped(cond, byValue);

				this.budgets = this.budgetsOriginal.sort(resultByValue);
			} else if (this.sortFilter[field] === 0) {
				this.budgets = [...this.budgetsOriginal];
			}
		},
		getColorChevrons(field) {
			if (this.sortFilter[field] > 0) {
				return '#ffffff';
			}
			return '#4dbcba';
		}
	}
};
</script>
