<template>
	<errorContainer :error="erreur" :warning="warning">
		<custom-loading-planning :loading="isLoading" :label="FormMSG(129, 'Prepare data of budgets')" />
		<b-row v-if="BudgetGlobal && BudgetTotalOneDep" class="form">
			<b-col v-if="!$screen.sm" md="12">
				<!-- <h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ FormMSG(231, 'Budget') }}
				</h1> -->
			</b-col>
			<b-col cols="12" xl="12">
				<b-card>
					<b-row class="mb-3">
						<b-col sm="8">
							<h1>{{ this.FormMSG(1, 'Budget for department:') + ' &laquo;' + department.message + '&raquo;' }}</h1>
						</b-col>
						<b-col sm="4" class="text-right" v-if="showBackButton()">
							<b-button :block="!$screen.md" @click="$router.push('/budget?tab=0')" variant="success" size="md">
								{{ this.FormMSG(2, 'Back to global budget') }} <i class="icon-action-undo"></i>
							</b-button>
						</b-col>
					</b-row>
					<total-general-budget id-collapse="CollapseForBudgetForOneDep" :budget-total="BudgetTotalOneDep" />
					<b-row class="mt-3">
						<b-col cols="12" xl="12">
							<CardListBuilder custom-class="hide-on-desktop" :items="BudgetGlobal" :fields="bgFields">
								<template slot="actions" slot-scope="data">
									<b-button variant="primary" size="sm" @click="rowClicked(data.item)">
										<i class="icon-eye"></i>
									</b-button>
								</template>
							</CardListBuilder>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col>
							<table-hierarchical-budget :budget-parents="budgetParents" hide-lock :department-id="departmentId" hide-action />
						</b-col>
					</b-row>
				</b-card>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';

import Loading from 'vue-loading-overlay';
import { isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency, rendKgCo2 } from '~helpers';
import TotalGeneralBudget from '@/components/Budget/TotalGeneralBudget';
import TableHierarchicalBudget from '@/components/Budget/TableHierarchicalBudget';
import { getDepartment } from '@/cruds/department.crud';
import { getBudgetHierarchicalAsJSON } from '@/cruds/budget.crud';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';

const query_budget_global_one_dep = gql`
	query ($DepartmentNumber: Int!) {
		GetBudgetDataDetailList(DepartmentNumber: $DepartmentNumber) {
			category
			departmentName
			department
			costCenter
			amountTotal
			contracted
			kgCoTwo
			purchaseOrdersTotal
			salaries
			amountLeft
			budgetDetail {
				initialBudget
				contentType
				description
			}
		}
	}
`;

const query_budget_global = gql`
	query ($DepartmentNumber: Int) {
		GetBudgetDataList(DepartmentNumber: $DepartmentNumber) {
			departmentName
			department
			costCenter
			amountTotal
			expenseTotal
			salaries
			contracted
			kgCoTwo
			purchaseOrdersTotal
			amountLeft
			initialSalaries
			initialExpenses
			budgetDetail {
				initialBudget
				contentType
			}
		}
	}
`;

export default {
	name: 'BudgetForOneDepartment',
	mixins: [languageMessages],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		TotalGeneralBudget,
		TableHierarchicalBudget,
		CustomLoadingPlanning
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			BudgetGlobal: [],
			currentPage: 1,
			perPage: 0,
			filter: '',
			warning: '',
			successModal: false,
			isLoading: false,
			BudgetTotalOneDep: {
				amountTotal: 0,
				expenseTotal: 0,
				contracted: 0,
				spent: 0,
				salaries: 0,
				initial: 0,
				purchaseOrdersTotal: 0,
				amountLeft: 0,
				initialExpenses: 0,
				initialSalaries: 0,
				kgCoTwoExpenses: 0,
				kgCoTwoSalaries: 0,
				kgCoTwoTotal: 0,
				totalVariance: 0,
				budgetDetailInitialBudget: 0
			},
			budgetParents: [],
			department: '',
			departmentId: null,
			isLoading: false
		};
	},

	computed: {
		bgFields() {
			return [
				{
					key: 'costCenter',
					label: this.FormMSG(16, 'Reference'),
					formatter: (value) => {
						return value;
					},
					sortable: true
				},
				{
					key: 'budgetDetail.description',
					label: this.FormMSG(20, 'Category'),
					sortable: true
				},
				{
					key: 'budgetDetail.contentType',
					label: this.FormMSG(28, 'Type'),
					formatter: (value) => {
						//console.log("value=",value);
						//  return value;
						return this.GetTextFromMenuNumberAndMenuValue(1015, value);
					},
					sortable: true
				},
				{
					key: 'budgetDetail.initial',
					label: this.FormMSG(21, 'Initial'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'contracted',
					label: this.FormMSG(27, 'Contracts'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					Name: 'amountTotal',
					key: 'amountTotal',
					label: this.FormMSG(22, 'Spent'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'purchaseOrdersTotal',
					label: this.FormMSG(23, 'Purchase Orders'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (value) => {
						return this.displayKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				},
				{
					key: 'amountLeft',
					label: this.FormMSG(24, 'Left'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				}
			];
		},
		styleObject: function () {
			var myObj = {
				color: 'black',
				fontSize: '14px'
			};
			if (this.BudgetTotalOneDep.amountLeft >= 0) {
				myObj.color = 'green';
			} else {
				myObj.color = 'red';
			}
			return myObj;
		}
	},

	async mounted() {
		setTimeout(async () => {
			this.departmentId = store.state.myProfile.department;
			this.loadTotalsForChosenDepartment();
			await this.$store.dispatch('expenses/getDepartmentsForSalary');
			this.department = await getDepartment(this.departmentId);
			await this.getBudgetHierarchicalDataList();
		}, 500);
	},

	methods: {
		async getBudgetHierarchicalDataList() {
			this.isLoading = true;
			const result = await getBudgetHierarchicalAsJSON(store.state.myProject.startDate, store.state.myProject.endDate, this.departmentId, true);
			this.budgetParents = JSON.parse(result);

			if (this.budgetParents) {
				await this.updateBudgetTotalForEach();
			}
			this.isLoading = false;
		},
		async updateBudgetTotalForEach() {
			this.initBudgetTotal();
			if (this.budgetParents.length > 0) {
				this.budgetParents.forEach((BudgetData) => {
					this.BudgetTotalOneDep.kgCoTwoTotal += BudgetData.kgCoTwo;

					if (this.BudgetTotalOneDep.salaries == 0) {
						this.BudgetTotalOneDep.kgCoTwoExpenses += BudgetData.kgCoTwo;
					} else {
						this.BudgetTotalOneDep.kgCoTwoSalaries += BudgetData.kgCoTwo;
					}

					this.BudgetTotalOneDep.amountLeft += BudgetData.amountLeft;
					this.BudgetTotalOneDep.initial += BudgetData.budgetDetailInitial;
					this.BudgetTotalOneDep.salaries += BudgetData.salaries;
					this.BudgetTotalOneDep.contracted += BudgetData.contracted;
					this.BudgetTotalOneDep.initialSalaries += BudgetData.initialSalaries;
					this.BudgetTotalOneDep.initialExpenses += BudgetData.initialExpenses;
					this.BudgetTotalOneDep.purchaseOrdersTotal += BudgetData.purchaseOrdersTotal;
					this.BudgetTotalOneDep.expenseTotal += BudgetData.expenseTotal;
					this.BudgetTotalOneDep.amountTotal += BudgetData.amountTotal;
					this.BudgetTotalOneDep.spent =
						this.BudgetTotalOneDep.salaries + this.BudgetTotalOneDep.expenseTotal + this.BudgetTotalOneDep.purchaseOrdersTotal;
					this.BudgetTotalOneDep.totalVariance += BudgetData.budgetDetailVariance;
					this.BudgetTotalOneDep.budgetDetailInitialBudget += BudgetData.budgetDetailInitialBudget;

					if (BudgetData.amountLeft < 0) {
						BudgetData._cellVariants = {
							amountLeft: 'danger'
						};
					} else {
						BudgetData._cellVariants = {
							amountLeft: 'success'
						};
					}
				});
			}
		},
		initBudgetTotal() {
			this.BudgetTotalOneDep.amountLeft = 0;
			this.BudgetTotalOneDep.initial = 0;
			this.BudgetTotalOneDep.salaries = 0;
			this.BudgetTotalOneDep.contracted = 0;
			this.BudgetTotalOneDep.initialSalaries = 0;
			this.BudgetTotalOneDep.initialExpenses = 0;
			this.BudgetTotalOneDep.kgCoTwoSalaries = 0;
			this.BudgetTotalOneDep.kgCoTwoTotal = 0;
			this.BudgetTotalOneDep.kgCoTwoExpenses = 0;
			this.BudgetTotalOneDep.purchaseOrdersTotal = 0;
			this.BudgetTotalOneDep.expenseTotal = 0;
			this.BudgetTotalOneDep.amountTotal = 0;
			this.BudgetTotalOneDep.spent = 0;
			this.BudgetTotalOneDep.totalVariance = 0;
			this.BudgetTotalOneDep.budgetDetailInitialBudget = 0;
		},
		showBackButton() {
			if (this.$route.params.department === undefined) {
				// we are in my budget, do not show button
				return false;
			}
			return true;
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		},
		// updateBudgetTotalForEach(BudgetData) {
		//     // setting row color
		//     // https://bootstrap-vue.js.org/docs/reference/color-variants
		//     //console.log("budgetdata",BudgetData)
		//     if (BudgetData.budgetDetail.contentType == 0) {
		//         this.BudgetTotalOneDep.kgCoTwoExpenses += BudgetData.kgCoTwo;
		//     } else {
		//         this.BudgetTotalOneDep.kgCoTwoSalaries += BudgetData.kgCoTwo;
		//     }
		//     //console.log("this.BudgetTotalOneDep.kgCoTwoSalaries",this.BudgetTotalOneDep.kgCoTwoSalaries);
		//     if (BudgetData.amountLeft < 0) {
		//         //BudgetData._rowVariant = 'danger';
		//         BudgetData._cellVariants = {
		//             amountLeft: 'danger'
		//         };
		//     } else {
		//         BudgetData._cellVariants = {
		//             amountLeft: 'success'
		//         };
		//         //BudgetData._rowVariant = 'success';
		//     }
		// },
		loadTotalsForChosenDepartment() {
			if (this.$route.params.department === undefined) {
				// we are in my budget , loading for my department
				var DepartmentNumber = store.state.myProfile.department;
				//console.log("in loadTotalsForChosenDepartment MY BUDGET department:", DepartmentNumber);
				// load totals for my budget (not necessary if comming from globalbudget)
				this.isLoading = true;
				//console.log("this.BudgetTotalOneDep:", this.BudgetTotalOneDep);
				//console.log("DepartmentNumber:", DepartmentNumber);
				this.$apollo
					.query({
						query: query_budget_global,
						variables: {
							DepartmentNumber
						},
						// options: { fetchPolicy: 'network-only' },
						// options: { fetchPolicy: 'no-cache' },
						// force update from server
						fetchPolicy: 'network-only'
					})
					.then((result) => {
						//console.log("loadTotalsForEachCategoryOfDepartment:", result.data);
						this.BudgetTotalOneDep = result.data.GetBudgetDataList[0];
						if (!isNil(this.BudgetTotalOneDep)) {
							this.BudgetTotalOneDep.kgCoTwoExpenses = 0;
							this.BudgetTotalOneDep.kgCoTwoSalaries = 0;
							this.BudgetTotalOneDep.initial = this.BudgetTotalOneDep.budgetDetail.initialBudget;
							this.BudgetTotalOneDep.kgCoTwoTotal = this.BudgetTotalOneDep.kgCoTwo;
							this.BudgetTotalOneDep.spentTotal =
								this.BudgetTotalOneDep.expenseTotal + this.BudgetTotalOneDep.salaries + this.BudgetTotalOneDep.purchaseOrdersTotal;
							this.BudgetTotalOneDep.spent = this.BudgetTotalOneDep.spentTotal;
							//console.log("loadTotalsForChosenDepartment:", this.BudgetTotalOneDep);
							this.isLoading = false;
							this.loadTotalsForEachCategoryOfDepartment();
						} else {
							this.BudgetTotalOneDep = {
								amountTotal: 0,
								expenseTotal: 0,
								contracted: 0,
								spent: 0,
								salaries: 0,
								initial: 0,
								purchaseOrdersTotal: 0,
								amountLeft: 0,
								initialExpenses: 0,
								initialSalaries: 0,
								kgCoTwoExpenses: 0,
								kgCoTwoSalaries: 0,
								kgCoTwoTotal: 0
							};
						}
					})
					.catch((error) => {
						//console.log(error)
						this.erreur = error;
					})
					.finally(() => (this.isLoading = false));
			} else {
				// called from production
				//console.log("in loadTotalsForChosenDepartment ALL DEPARTMENT");
				this.BudgetTotalOneDep = store.getCurBudgetDataForDepartment();
				if (isNil(this.BudgetTotalOneDep)) {
					this.$router.push(`/budget`);
				} else {
					this.BudgetTotalOneDep.kgCoTwoExpenses = 0;
					this.BudgetTotalOneDep.kgCoTwoSalaries = 0;
					this.BudgetTotalOneDep.initial = this.BudgetTotalOneDep.budgetDetail.initial;
					this.BudgetTotalOneDep.kgCoTwoTotal = this.BudgetTotalOneDep.kgCoTwo;
					//console.log("this.BudgetTotalOneDep", this.BudgetTotalOneDep);
					this.BudgetTotalOneDep.spentTotal =
						this.BudgetTotalOneDep.expenseTotal + this.BudgetTotalOneDep.salaries + this.BudgetTotalOneDep.purchaseOrdersTotal;
					this.BudgetTotalOneDep.spent = this.BudgetTotalOneDep.spentTotal;
					//this.BudgetTotalOneDep.kgCoTwoTotalSalariesPlusExpenses = this.BudgetTotalOneDep.kgCoTwoExpenses + this.BudgetTotalOneDep.kgCoTwoSalaries;
					this.loadTotalsForEachCategoryOfDepartment();
				}
			}
		},
		loadTotalsForEachCategoryOfDepartment() {
			// loading  category totals table for the chosen department
			this.isLoading = true;
			if (this.$route.params.department === undefined) {
				// in my budget screen use profile to find department
				var DepartmentNumber = store.state.myProfile.department;
				//  console.log("in loadTotalsForEachCategoryOfDepartment MY BUDGET department:", DepartmentNumber);
			} else {
				// in my budget for one department  screen use parameter in route to find department
				//  console.log("in loadTotalsForEachCategoryOfDepartment ALL DEPARTMENT");
				var DepartmentNumber = parseInt(this.$route.params.department, 10);
			}

			//console.log("this.BudgetTotalOneDep:", this.BudgetTotalOneDep);
			//console.log("DepartmentNumber:", DepartmentNumber);
			this.$apollo
				.query({
					query: query_budget_global_one_dep,
					variables: {
						DepartmentNumber
					},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					//console.log("loadTotalsForEachCategoryOfDepartment:", result.data);
					if (result) {
						result.data.GetBudgetDataDetailList.forEach(this.updateBudgetTotalForEach);
						this.BudgetGlobal = result.data.GetBudgetDataDetailList;
						this.isLoading = false;
					}
					//console.log("loadTotalsForEachCategoryOfDepartment:", this.BudgetGlobal);
				})
				.catch((error) => {
					//console.log(error)
					this.erreur = error;
				});
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		budgetPerCategoryDetailLink(item) {
			return `/budget/department/${item.department.toString()}/category/${item.category.toString()}`;
		},
		budgetDepSalaryDetailLink(item) {
			return `/budget/department/${item.department.toString()}/salariesForCategory/${item.category.toString()}`;
		},
		rowClicked(item) {
			// console.log('1- BudgetForOneDepartment: ', this.$router.currentRoute.path);
			// console.log('2- BudgetForOneDepartment: ', { item });
			// console.log('3- BudgetForOneDepartment: ', { item });
			// copy item content into curTs structure.
			store.setCurBudgetDataForDepartmentCategory(item);
			if (item.category != 10 && item.budgetDetail.contentType != 1) {
				const budgetDepCatDetailLink = this.budgetPerCategoryDetailLink(item);
				this.$router.push({
					path: budgetDepCatDetailLink
				});
			} else {
				const budgetDepSalaryDetailLink = this.budgetDepSalaryDetailLink(item);
				//                  console.log("link:",budgetDepSalaryDetailLink);
				this.$router.push({
					path: budgetDepSalaryDetailLink
				});
			}
		}
	}
};
</script>
